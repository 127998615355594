import React from "react"
import { SpaceBetween } from "@opensea/ui-kit"
import { Dropdown } from "@/design-system/Dropdown"
import {
  bn,
  roundAboveMin,
  traitsToPercentage,
} from "@/lib/helpers/numberUtils"
import { SelectedTrait, Trait, TraitValue } from "./types"

type TraitSelectorSectionProps = {
  totalAssetCount: number | null
  trait: Trait
  onSelect: (selection: SelectedTrait) => void
  showTraitFloor: boolean
}

export const TraitSelectorSection = ({
  totalAssetCount,
  onSelect,
  trait,
  showTraitFloor,
}: TraitSelectorSectionProps) => {
  return (
    <Dropdown.List.Group groupLabel={trait.key}>
      {trait.counts.map((traitValue: TraitValue) => (
        <Dropdown.Item
          key={`${trait.key}|${traitValue.value}`}
          onClick={() => {
            onSelect({
              key: trait.key,
              value: traitValue.value,
              floor: traitValue.floor,
              count: traitValue.count,
            })
          }}
        >
          <Dropdown.Item.Content>
            <SpaceBetween className="w-full items-baseline">
              <Dropdown.Item.Title>{traitValue.value}</Dropdown.Item.Title>
              {showTraitFloor && (
                <Dropdown.Item.Value>
                  {traitValue.floor
                    ? `${roundAboveMin(bn(traitValue.floor.unit), 2)} ${
                        traitValue.floor.symbol
                      }`
                    : "---"}
                </Dropdown.Item.Value>
              )}
            </SpaceBetween>
            <Dropdown.Item.Description>
              {totalAssetCount
                ? `${traitsToPercentage(totalAssetCount, traitValue.count)}%`
                : "---"}
            </Dropdown.Item.Description>
          </Dropdown.Item.Content>
        </Dropdown.Item>
      ))}
    </Dropdown.List.Group>
  )
}
