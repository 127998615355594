import BigNumber from "bignumber.js"
import { price$data } from "@/lib/graphql/__generated__/price.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { bn } from "./numberUtils"

const priceFragment = graphql`
  fragment price on OrderV2Type @inline {
    priceType {
      unit
    }
  }
`

export const readPrice = inlineFragmentize<price$data, BigNumber>(
  priceFragment,
  ({ priceType }) => {
    return bn(priceType.unit)
  },
)
