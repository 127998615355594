/**
 * @generated SignedSource<<3906cd3f821cdbc97d345e3302bb2fe0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferModal_asset$data = {
  readonly collection: {
    readonly " $fragmentSpreads": FragmentRefs<"ContextualPriceList_collection">;
  };
  readonly relayId: string;
  readonly tradeSummary: {
    readonly bestAsk: {
      readonly perUnitPriceType: {
        readonly usd: string;
        readonly " $fragmentSpreads": FragmentRefs<"AboveListingPriceWarningModal_pricing">;
      };
      readonly taker: {
        readonly address: string;
      } | null;
    } | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ItemOfferDetails_item" | "useOfferModalAdapter_asset">;
  readonly " $fragmentType": "OfferModal_asset";
};
export type OfferModal_asset$key = {
  readonly " $data"?: OfferModal_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferModal_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferModal_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relayId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionType",
      "kind": "LinkedField",
      "name": "collection",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContextualPriceList_collection"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TradeSummaryType",
      "kind": "LinkedField",
      "name": "tradeSummary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAsk",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountType",
              "kind": "LinkedField",
              "name": "taker",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPriceType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AboveListingPriceWarningModal_pricing"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "chain",
          "variableName": "chain"
        }
      ],
      "kind": "FragmentSpread",
      "name": "useOfferModalAdapter_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemOfferDetails_item"
    }
  ],
  "type": "AssetType",
  "abstractKey": null
};

(node as any).hash = "ee4a8a0a794b4dbf349f7d8839f4971b";

export default node;
