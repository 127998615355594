/**
 * @generated SignedSource<<32155b7ef78375b651963a2927d03c14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContextualPriceList_tradeSummary$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ContextualPriceListBestOfferItem_tradeSummary">;
  readonly " $fragmentType": "ContextualPriceList_tradeSummary";
};
export type ContextualPriceList_tradeSummary$key = {
  readonly " $data"?: ContextualPriceList_tradeSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContextualPriceList_tradeSummary">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContextualPriceList_tradeSummary",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContextualPriceListBestOfferItem_tradeSummary"
    }
  ],
  "type": "TradeSummaryType",
  "abstractKey": null
};

(node as any).hash = "43cef18cff27a33874629e91f7e637a3";

export default node;
