import React, { Suspense } from "react"
import { classNames, twDataIdSelector, Skeleton } from "@opensea/ui-kit"
import BigNumber from "bignumber.js"
import { graphql, useFragment, useLazyLoadQuery } from "react-relay"
import styled from "styled-components"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { Item } from "@/design-system/Item"
import { List } from "@/design-system/List"
import { useTranslate } from "@/hooks/useTranslate"
import { ContextualPriceList_collection$key } from "@/lib/graphql/__generated__/ContextualPriceList_collection.graphql"
import { ContextualPriceList_tradeSummary$key } from "@/lib/graphql/__generated__/ContextualPriceList_tradeSummary.graphql"
import { ContextualPriceListBestOfferItem_tradeSummary$key } from "@/lib/graphql/__generated__/ContextualPriceListBestOfferItem_tradeSummary.graphql"
import {
  ContextualPriceListBestOfferQuery,
  ContextualPriceListBestOfferQuery$variables,
} from "@/lib/graphql/__generated__/ContextualPriceListBestOfferQuery.graphql"
import { getFirstNode } from "@/lib/graphql/graphql"
import { bn, display } from "@/lib/helpers/numberUtils"

type Props = {
  balance: BigNumber
  balanceSymbol: string
  collection: ContextualPriceList_collection$key | null
  tradeSummary: ContextualPriceList_tradeSummary$key | null
  selectedTrait: SelectedTrait
}

export const ContextualPriceList = ({
  balance,
  balanceSymbol,
  collection: collectionDataKey,
  selectedTrait,
  tradeSummary: tradeSummaryDataKey,
}: Props) => {
  const t = useTranslate("orders")

  const collection = useFragment(
    graphql`
      fragment ContextualPriceList_collection on CollectionType {
        slug
        statsV2 {
          floorPrice {
            unit
            symbol
          }
        }
      }
    `,
    collectionDataKey,
  )

  const tradeSummary = useFragment(
    graphql`
      fragment ContextualPriceList_tradeSummary on TradeSummaryType {
        ...ContextualPriceListBestOfferItem_tradeSummary
      }
    `,
    tradeSummaryDataKey,
  )

  const selectedTraitFloorPrice = selectedTrait?.floor
    ? {
        symbol: selectedTrait.floor.symbol,
        unit: selectedTrait.floor.unit,
      }
    : undefined

  const floorPrice = selectedTraitFloorPrice || collection?.statsV2.floorPrice

  const trait: BestOfferItemProps["trait"] =
    selectedTrait?.key && selectedTrait.value
      ? {
          name: selectedTrait.key,
          values: [selectedTrait.value],
        }
      : undefined

  return (
    <StyledList
      className={classNames(
        twDataIdSelector(Item, "[&_[data-id=Item]]:py-1"),
        twDataIdSelector(Item, "[&_[data-id=Item]]:px-4"),
      )}
      showBorder={false}
      variant="condensed"
    >
      <Item>
        <Item.Avatar className="mr-3" icon="account_balance_wallet" size={20} />
        <Item.Content>
          <Item.Title>{t("offerModal.balance", "Balance")}</Item.Title>
        </Item.Content>
        <Item.Side>
          <Item.Title>
            {display(balance.toNumber())} {balanceSymbol}
          </Item.Title>
        </Item.Side>
      </Item>

      <Item>
        <Item.Content>
          <Item.Title>{t("offerModal.floorPrice", "Floor price")}</Item.Title>
        </Item.Content>
        <Item.Side>
          <Item.Title>
            {floorPrice ? (
              <>
                {display(floorPrice.unit)} {floorPrice.symbol}
              </>
            ) : (
              EMPTY_PRICE_DISPLAY
            )}
          </Item.Title>
        </Item.Side>
      </Item>

      {collection && (
        <Suspense
          fallback={
            <Item>
              <Item.Content>
                <Item.Title>
                  {t("offerModal.bestOffer", "Best offer")}
                </Item.Title>
              </Item.Content>
              <Item.Side>
                <Item.Title>
                  <Skeleton>
                    <Skeleton.Line className="w-10" />
                  </Skeleton>
                </Item.Title>
              </Item.Side>
            </Item>
          }
        >
          <BestOfferItem
            collectionSlug={collection.slug}
            hasTrait={Boolean(trait)}
            tradeSummary={tradeSummary}
            trait={trait}
          />
        </Suspense>
      )}
    </StyledList>
  )
}

type BestOfferItemProps = ContextualPriceListBestOfferQuery$variables & {
  tradeSummary: ContextualPriceListBestOfferItem_tradeSummary$key | null
}

const BestOfferItem = ({
  collectionSlug,
  trait,
  hasTrait,
  tradeSummary: tradeSummaryDataKey,
}: BestOfferItemProps) => {
  const t = useTranslate("orders")

  const tradeSummary = useFragment(
    graphql`
      fragment ContextualPriceListBestOfferItem_tradeSummary on TradeSummaryType {
        bestBid {
          perUnitPriceType {
            unit
            symbol
            usd
          }
        }
      }
    `,
    tradeSummaryDataKey,
  )

  const { bestCollectionOffers, bestTraitOffers } =
    useLazyLoadQuery<ContextualPriceListBestOfferQuery>(
      graphql`
        query ContextualPriceListBestOfferQuery(
          $collectionSlug: CollectionSlug!
          $trait: TraitInputType
          $hasTrait: Boolean!
        ) {
          bestCollectionOffers: collection(collection: $collectionSlug) {
            collectionOffers(first: 1) {
              edges {
                node {
                  perUnitPriceType {
                    unit
                    symbol
                    usd
                  }
                }
              }
            }
          }
          bestTraitOffers: collection(collection: $collectionSlug)
            @include(if: $hasTrait) {
            collectionOffers(first: 1, traitCriteria: $trait) {
              edges {
                node {
                  perUnitPriceType {
                    unit
                    symbol
                    usd
                  }
                }
              }
            }
          }
        }
      `,
      { collectionSlug, trait, hasTrait },
    )

  const bestCollectionOffer = getFirstNode(
    bestCollectionOffers?.collectionOffers,
  )

  const bestTraitOffer = getFirstNode(bestTraitOffers?.collectionOffers)

  const maxUsd = BigNumber.maximum(
    bestCollectionOffer?.perUnitPriceType.usd ?? 0,
    bestTraitOffer?.perUnitPriceType.usd ?? 0,
    tradeSummary?.bestBid?.perUnitPriceType.usd ?? 0,
  )

  const bestOffer = [
    bestCollectionOffer,
    bestTraitOffer,
    tradeSummary?.bestBid,
  ].find(offer => bn(maxUsd).eq(offer?.perUnitPriceType.usd ?? 0))

  return (
    <Item>
      <Item.Content>
        <Item.Title>{t("offerModal.bestOffer", "Best offer")}</Item.Title>
      </Item.Content>
      <Item.Side>
        <Item.Title>
          {bestOffer ? (
            <>
              {display(bestOffer.perUnitPriceType.unit)}{" "}
              {bestOffer.perUnitPriceType.symbol}
            </>
          ) : (
            EMPTY_PRICE_DISPLAY
          )}
        </Item.Title>
      </Item.Side>
    </Item>
  )
}

const StyledList = styled(List)`
  background: ${props => props.theme.colors.components.background.gray1};
  padding-top: 12px;
  padding-bottom: 12px;
`
