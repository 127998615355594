import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { useTranslate } from "@/hooks/useTranslate"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { CoreMarketplaceActionErrorFallback } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionErrorFallback.react"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  CreateOfferActionModalQuery,
  CreateOfferActionModalQuery$variables,
} from "@/lib/graphql/__generated__/CreateOfferActionModalQuery.graphql"

export type CreateOfferActionModalProps = Omit<
  BaseBlockchainActionProps,
  "title"
> &
  CreateOfferActionModalQuery$variables & { isEnglishAuction?: boolean }

const BaseCreateOfferActionModal = ({
  onEnd,
  onError,
  isEnglishAuction,
  ...variables
}: CreateOfferActionModalProps) => {
  const t = useTranslate("components")

  const data = useLazyLoadQuery<CreateOfferActionModalQuery>(
    graphql`
      query CreateOfferActionModalQuery(
        $item: AssetQuantityInputType!
        $price: PaymentAssetQuantityInputType!
        $closedAt: DateTime!
      ) {
        blockchain {
          createOfferActions(item: $item, price: $price, closedAt: $closedAt) {
            ...BlockchainActionList_data
          }
        }
      }
    `,
    variables,
    { fetchPolicy: "network-only" },
  )

  return (
    <BlockchainActionList
      dataKey={data.blockchain.createOfferActions}
      overrides={{
        CreateOrderActionType: {
          props: {
            title: isEnglishAuction
              ? t(
                  "blockchain.createOfferModal.englishAuctionTitle",
                  "Place your bid",
                )
              : undefined,
          },
        },
      }}
      onEnd={onEnd}
      onError={onError}
    />
  )
}

const CreateOfferActionModalWithSuspense = (
  props: CreateOfferActionModalProps,
) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={props => {
        return (
          <CoreMarketplaceActionErrorFallback
            errorTags={errorTags}
            trackServerError={trackServerError}
            {...props}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <BaseCreateOfferActionModal {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export const CreateOfferActionModal = (props: CreateOfferActionModalProps) => {
  return (
    <CoreMarketplaceActionTrackingContextProvider action="IndividualOffer">
      <CreateOfferActionModalWithSuspense {...props} />
    </CoreMarketplaceActionTrackingContextProvider>
  )
}
