/**
 * @generated SignedSource<<a1c8b7ff9ca3b22fb4426c315f664c5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OfferModal_tradeSummary$data = {
  readonly bestAsk: {
    readonly closedAt: string | null;
    readonly payment: {
      readonly relayId: string;
    };
    readonly relayId: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ContextualPriceList_tradeSummary" | "useOfferModalAdapter_tradeData">;
  readonly " $fragmentType": "OfferModal_tradeSummary";
};
export type OfferModal_tradeSummary$key = {
  readonly " $data"?: OfferModal_tradeSummary$data;
  readonly " $fragmentSpreads": FragmentRefs<"OfferModal_tradeSummary">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OfferModal_tradeSummary",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestAsk",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentAssetType",
          "kind": "LinkedField",
          "name": "payment",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOfferModalAdapter_tradeData"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContextualPriceList_tradeSummary"
    }
  ],
  "type": "TradeSummaryType",
  "abstractKey": null
};
})();

(node as any).hash = "5b8e6528fe78e02e266cc2895e7a5fec";

export default node;
