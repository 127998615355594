/**
 * @generated SignedSource<<837dbe9e225375706232701b6eea4289>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type price$data = {
  readonly priceType: {
    readonly unit: string;
  };
  readonly " $fragmentType": "price";
};
export type price$key = {
  readonly " $data"?: price$data;
  readonly " $fragmentSpreads": FragmentRefs<"price">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "price"
};

(node as any).hash = "6ca5012c77fc153fc1ac32d8529a2e55";

export default node;
