import React, { Suspense } from "react"
import { ErrorBoundary } from "@sentry/nextjs"
import { graphql, useLazyLoadQuery } from "react-relay"
import { BlockchainActionList } from "@/components/blockchain/BlockchainActionList"
import {
  BaseBlockchainActionProps,
  BlockchainActionModalContent,
} from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { CoreMarketplaceActionTrackingContextProvider } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionContext.react"
import { CoreMarketplaceActionErrorFallback } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/CoreMarketplaceActionErrorFallback.react"
import { useTrackServerError } from "@/lib/analytics/TrackingContext/contexts/core-marketplace-actions/hooks"
import {
  CreateCollectionOfferActionModalQuery,
  CreateCollectionOfferActionModalQuery$variables,
} from "@/lib/graphql/__generated__/CreateCollectionOfferActionModalQuery.graphql"

export type CreateCollectionOfferActionModalProps = Omit<
  BaseBlockchainActionProps,
  "title"
> &
  CreateCollectionOfferActionModalQuery$variables

const BaseCreateCollectionOfferActionModal = ({
  onEnd,
  onError,
  ...variables
}: CreateCollectionOfferActionModalProps) => {
  const data = useLazyLoadQuery<CreateCollectionOfferActionModalQuery>(
    graphql`
      query CreateCollectionOfferActionModalQuery(
        $price: PaymentAssetQuantityInputType!
        $closedAt: DateTime!
        $assetContract: AssetContractInputType!
        $collection: CollectionSlug!
        $trait: StringTraitConfig
        $quantity: BigNumberScalar
      ) {
        blockchain {
          createCollectionOfferActions(
            price: $price
            closedAt: $closedAt
            assetContract: $assetContract
            collection: $collection
            trait: $trait
            quantity: $quantity
          ) {
            ...BlockchainActionList_data
          }
        }
      }
    `,
    variables,
    { fetchPolicy: "network-only" },
  )

  return (
    <BlockchainActionList
      dataKey={data.blockchain.createCollectionOfferActions}
      onEnd={onEnd}
      onError={onError}
    />
  )
}

const CreateCollectionOfferActionModalWithSuspense = (
  props: CreateCollectionOfferActionModalProps,
) => {
  const [trackServerError, errorTags] = useTrackServerError()

  return (
    <ErrorBoundary
      fallback={props => {
        return (
          <CoreMarketplaceActionErrorFallback
            errorTags={errorTags}
            trackServerError={trackServerError}
            {...props}
          />
        )
      }}
    >
      <Suspense fallback={<BlockchainActionModalContent.Skeleton />}>
        <BaseCreateCollectionOfferActionModal {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export const CreateCollectionOfferActionModal = (
  props: CreateCollectionOfferActionModalProps,
) => {
  return (
    <CoreMarketplaceActionTrackingContextProvider
      action={props.trait ? "TraitOffer" : "CollectionOffer"}
    >
      <CreateCollectionOfferActionModalWithSuspense {...props} />
    </CoreMarketplaceActionTrackingContextProvider>
  )
}
