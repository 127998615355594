import React, { createContext, useState } from "react"
import { SelectedTrait } from "@/components/traits/TraitSelector"
import { BigNumber } from "@/lib/helpers/numberUtils"

type CollectionOfferContextType = {
  selectedTrait: SelectedTrait
  setSelectedTrait: (trait: SelectedTrait) => unknown
  defaultOfferPrice?: BigNumber
  setDefaultOfferPrice: (defaultOfferPrice: BigNumber) => void
}

const DEFAULT_CONTEXT: CollectionOfferContextType = {
  selectedTrait: null,
  setSelectedTrait: () => false,
  setDefaultOfferPrice: () => null,
}

export const CollectionOfferContext = createContext(DEFAULT_CONTEXT)

export type CollectionOfferProviderProps = {
  defaultSelectedTrait?: SelectedTrait
  defaultOfferPrice?: BigNumber
  children: React.ReactNode
}

export const CollectionOfferProvider = ({
  defaultSelectedTrait,
  defaultOfferPrice: defaultOfferPriceProp,
  children,
}: CollectionOfferProviderProps) => {
  const [selectedTrait, setSelectedTrait] = useState<SelectedTrait>(
    defaultSelectedTrait ?? null,
  )
  const [defaultOfferPrice, setDefaultOfferPrice] = useState<
    BigNumber | undefined
  >(defaultOfferPriceProp)

  return (
    <CollectionOfferContext.Provider
      value={{
        selectedTrait,
        setSelectedTrait,
        defaultOfferPrice,
        setDefaultOfferPrice,
      }}
    >
      {children}
    </CollectionOfferContext.Provider>
  )
}
