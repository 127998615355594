import { SelectedTrait } from "@/components/traits/TraitSelector"
import { getTrackingFn } from "@/lib/analytics"
import { OfferModalFormData } from "./OfferModal.react"

export type OfferModalEventParams = {
  offerType: "bid" | "collection offer" | "offer"
  collection:
    | {
        relayId: string
        slug: string
      }
    | undefined
  asset:
    | {
        relayId: string
        tokenAddress: string
        tokenId: string
      }
    | undefined
}
export const trackOpenOfferModal =
  getTrackingFn<OfferModalEventParams>("open offer modal")

export const trackClickMakeCollectionOffer = getTrackingFn(
  "click make collection offer",
)

export const trackSubmitOfferModalForm = getTrackingFn<
  OfferModalEventParams &
    OfferModalFormData & {
      selectedTrait: SelectedTrait
    }
>("submit offer modal form")

export const trackOfferModalTraitSelected = getTrackingFn<
  OfferModalEventParams & {
    selectedTrait: SelectedTrait
  }
>("select trait")
