import React, { useState } from "react"
import { CenterAligned, Text } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackOpenAboveListingPriceWarningModal } from "@/lib/analytics/events/itemEvents"
import { AboveListingPriceWarningModal_pricing$key } from "@/lib/graphql/__generated__/AboveListingPriceWarningModal_pricing.graphql"
import {
  BigNumber,
  normalizePriceDisplay,
  percentageDifference,
} from "@/lib/helpers/numberUtils"

type Props = {
  perUnitListingPrice: AboveListingPriceWarningModal_pricing$key | null
  perUnitUsdPrice: BigNumber | null
  onClose: () => unknown
  onConfirm: () => unknown
}

export const AboveListingPriceWarningModal = ({
  perUnitListingPrice: perUnitListingPriceDataKey,
  perUnitUsdPrice,
  onConfirm,
  onClose,
}: Props) => {
  const perUnitListingPrice = useFragment(
    graphql`
      fragment AboveListingPriceWarningModal_pricing on PriceType {
        usd
        unit
        symbol
      }
    `,
    perUnitListingPriceDataKey,
  )

  const t = useTranslate("orders")
  const { onPrevious } = useMultiStepFlowContext()
  const [isConfirming, setIsConfirming] = useState(false)
  useMountEffect(() => trackOpenAboveListingPriceWarningModal())

  const handleConfirm = async () => {
    setIsConfirming(true)
    await onConfirm()
    setIsConfirming(false)
  }

  const assetListingPriceUsd = perUnitListingPrice?.usd || ""

  // compare usd prices to avoid conflicts between
  // selected payment option and listed price currency
  // ie. Listed in eth but selected weth may have discrepancies
  const percentageAboveListingPriceUsd =
    assetListingPriceUsd && perUnitUsdPrice
      ? percentageDifference(assetListingPriceUsd, perUnitUsdPrice)
          .abs()
          .toFixed(2)
          .toString()
      : ""

  return (
    <>
      <Modal.Header onPrevious={onPrevious} />
      <Modal.Body>
        <Modal.Body.Title className="mb-6">
          {t(
            "flow.confirmHighListingHeader",
            "Make offer above listing price?",
          )}
        </Modal.Body.Title>
        <CenterAligned>
          <Text>
            {t(
              "flow.highListingModalMessage",
              "This offer is {{percent}} above the current listing price ({{perUnitListingPrice}} {{perUnitListingPriceSymbol}})",
              {
                percent: (
                  <b>{`${normalizePriceDisplay(
                    percentageAboveListingPriceUsd,
                    0,
                  )}%`}</b>
                ),
                perUnitListingPrice: perUnitListingPrice?.unit || "",
                perUnitListingPriceSymbol: perUnitListingPrice?.symbol || "",
              },
            )}
          </Text>
        </CenterAligned>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button variant="secondary" onClick={onClose}>
          {t("priceWarningModal.cancelCTA", "Cancel")}
        </Modal.Footer.Button>
        <Modal.Footer.Button
          disabled={isConfirming}
          isLoading={isConfirming}
          onClick={handleConfirm}
        >
          {t("flow.confirmHighListingAction", "Make offer")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </>
  )
}
