/**
 * @generated SignedSource<<154e3c40388ccc1e6159d246972e696d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type VerificationStatus = "MINTABLE" | "SAFELISTED" | "UNAPPROVED" | "VERIFIED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useOfferModalAdapter_tradeData$data = {
  readonly bestAsk: {
    readonly item: {
      readonly verificationStatus: VerificationStatus;
    };
    readonly orderType: OrderV2OrderType;
    readonly payment: {
      readonly relayId: string;
    };
    readonly perUnitPriceType: {
      readonly unit: string;
    };
    readonly relayId: string;
  } | null;
  readonly bestBid: {
    readonly payment: {
      readonly relayId: string;
    };
    readonly relayId: string;
    readonly " $fragmentSpreads": FragmentRefs<"price">;
  } | null;
  readonly " $fragmentType": "useOfferModalAdapter_tradeData";
};
export type useOfferModalAdapter_tradeData$key = {
  readonly " $data"?: useOfferModalAdapter_tradeData$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOfferModalAdapter_tradeData">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentAssetType",
  "kind": "LinkedField",
  "name": "payment",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOfferModalAdapter_tradeData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestAsk",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "orderType",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "item",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "verificationStatus",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "PriceType",
          "kind": "LinkedField",
          "name": "perUnitPriceType",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderV2Type",
      "kind": "LinkedField",
      "name": "bestBid",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "price",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "priceType",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TradeSummaryType",
  "abstractKey": null
};
})();

(node as any).hash = "e2e76fefadaf4fbc3d652d1900e30303";

export default node;
