import React, { useCallback, useEffect, useState } from "react"
import {
  Icon,
  UnstyledButton,
  Text,
  Label,
  Input,
  Alert,
  Flex,
} from "@opensea/ui-kit"
import { add, addMonths } from "date-fns"
import { Controller } from "react-hook-form"
import { useFragment } from "react-relay"
import { useUpdateEffect } from "react-use"
import { graphql } from "relay-runtime"
import styled from "styled-components"
import { BlockchainActionOnEnd } from "@/components/blockchain/BlockchainActionList/BlockchainActionModalContent.react"
import { CreateCollectionOfferActionModal } from "@/components/blockchain/orders/CreateCollectionOfferActionModal"
import { CreateOfferActionModal } from "@/components/blockchain/orders/CreateOfferActionModal"
import { InfoIcon } from "@/components/common/InfoIcon.react"
import { Link } from "@/components/common/Link"
import { AssetSuccessModalContent } from "@/components/modals/AssetSuccessModalContent.react"
import { AddFundsModalContent } from "@/components/trade/AddFundsModal"
import {
  getPaymentAssetOptions,
  PaymentTokenInput,
} from "@/components/trade/PaymentTokenInput"
import {
  LazyTraitSelector,
  SelectedTrait,
} from "@/components/traits/TraitSelector"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { SOLANA_OFFER_BALANCE_ARTICLE } from "@/constants/support-articles"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Button } from "@/design-system/Button"
import { DatePicker } from "@/design-system/DatePicker"
import { Form } from "@/design-system/Form"
import { Item } from "@/design-system/Item"
import {
  MOBILE_MODAL_PADDING,
  Modal,
  MODAL_PADDING,
} from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { Select } from "@/design-system/Select"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useReactivatableListingsWarningContent } from "@/features/assets/components/ListingWarning/useReactivatableListingsWarningContent.react"
import {
  ExpirationOption,
  useOfferExpirationOptions,
  useCollectionOfferExpirationOptions,
} from "@/features/orders/constants"
import { useCollectionOfferContext } from "@/features/orders/hooks/useCollectionOfferContext"
import {
  MAX_COLLECTION_OFFER_QUANTITY,
  useOfferModalAdapter,
} from "@/features/orders/hooks/useOfferModalAdapter"
import { useForm } from "@/hooks/useForm"
import { useIsMountedRef } from "@/hooks/useIsMounted"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { OfferModal_account$key } from "@/lib/graphql/__generated__/OfferModal_account.graphql"
import { OfferModal_asset$key } from "@/lib/graphql/__generated__/OfferModal_asset.graphql"
import { OfferModal_collectionData$key } from "@/lib/graphql/__generated__/OfferModal_collectionData.graphql"
import { OfferModal_tradeLimits$key } from "@/lib/graphql/__generated__/OfferModal_tradeLimits.graphql"
import { OfferModal_tradeSummary$key } from "@/lib/graphql/__generated__/OfferModal_tradeSummary.graphql"
import { getEthereumChain, hasBiddingBalance } from "@/lib/helpers/chainUtils"
import { dateFromISO8601 } from "@/lib/helpers/datetime"
import {
  BigNumber,
  bn,
  display,
  displayUSD,
  getOfferPricePrecision,
  isValidNumericInput,
  MAX_DECIMALS_FOR_OFFERS,
  offerPriceRounding,
} from "@/lib/helpers/numberUtils"
import { AboveListingPriceWarningModal } from "../AboveListingPriceWarningModal"
import {
  trackOpenOfferModal,
  trackSubmitOfferModalForm,
  trackOfferModalTraitSelected,
} from "./analytics"
import { CollectionOfferDetails } from "./components/CollectionOfferDetails"
import { ContextualPriceList } from "./components/ContextualPriceList/ContextualPriceList.react"
import { ItemOfferDetails } from "./components/ItemOfferDetails"
import { useNewOfferPrecision } from "@/hooks/useFlag"

export type OfferModalProps = {
  asset: OfferModal_asset$key | null
  collection: OfferModal_collectionData$key | null
  account: OfferModal_account$key | null
  tradeDataKey: OfferModal_tradeSummary$key | null
  tradeLimitsDataKey: OfferModal_tradeLimits$key
  onOrderCreated?: () => unknown
  onClose: () => unknown
  initialQuantity?: BigNumber
  initialPricePerUnit?: string
}

export type OfferModalFormData = {
  bidExpiration: Date
  paymentAssetRelayId: string
  pricePerUnit: string
  quantity: string
  selectedPaymentAssetBalance: BigNumber
}

const DEFAULT_EXPIRATION_OPTION: ExpirationOption["value"] = "3d"
export const DEFAULT_COLLECTION_EXPIRATION_OPTION: ExpirationOption["value"] =
  "7d"
export const DEFAULT_COLLECTION_EXPIRATION_OPTION_LABEL: ExpirationOption["label"] =
  "7 days"

export const OfferModal = ({
  asset: assetDataKey,
  collection: collectionDataKey,
  account: accountDataKey,
  tradeDataKey,
  tradeLimitsDataKey,
  onOrderCreated,
  onClose,
  initialQuantity,
  initialPricePerUnit,
}: OfferModalProps) => {
  const connectedAddress = useConnectedAddress()
  const { showSuccessMessage, showErrorMessage } = useToasts()
  const { onNext, onReplace } = useMultiStepFlowContext()
  const { selectedTrait, setSelectedTrait } = useCollectionOfferContext()
  const [showHiddenListingWarning, setShowHiddenListingWarning] =
    useState(false)
  const isNewOfferPrecisionRequired = useNewOfferPrecision()
  const isMountedRef = useIsMountedRef()
  const t = useTranslate("orders")

  const asset = useFragment(
    graphql`
      fragment OfferModal_asset on AssetType
      @argumentDefinitions(chain: { type: "ChainScalar" }) {
        relayId
        collection {
          ...ContextualPriceList_collection
        }
        tradeSummary {
          bestAsk {
            taker {
              address
            }
            perUnitPriceType {
              usd
              ...AboveListingPriceWarningModal_pricing
            }
          }
        }
        ...useOfferModalAdapter_asset @arguments(chain: $chain)
        ...ItemOfferDetails_item
      }
    `,
    assetDataKey,
  )

  const collection = useFragment(
    graphql`
      fragment OfferModal_collectionData on CollectionType {
        isTraitOffersEnabled
        name
        slug
        relayId
        statsV2 {
          floorPrice {
            usd
            symbol
          }
        }
        ...useOfferModalAdapter_collection
        ...CollectionOfferDetails_collection
        ...ContextualPriceList_collection
      }
    `,
    collectionDataKey,
  )

  const account = useFragment(
    graphql`
      fragment OfferModal_account on AccountType {
        offerProtectionEnabled
      }
    `,
    accountDataKey,
  )

  const offerProtectionEnabled = Boolean(account?.offerProtectionEnabled)

  const tradeData = useFragment(
    graphql`
      fragment OfferModal_tradeSummary on TradeSummaryType {
        bestAsk {
          relayId
          closedAt
          payment {
            relayId
          }
        }
        ...useOfferModalAdapter_tradeData
        ...ContextualPriceList_tradeSummary
      }
    `,
    tradeDataKey,
  )
  const bestAsk = tradeData?.bestAsk

  const tradeLimits = useFragment(
    graphql`
      fragment OfferModal_tradeLimits on TradeLimitsType {
        minimumBidUsdPrice
        ...useOfferModalAdapter_tradeLimits
      }
    `,
    tradeLimitsDataKey,
  )

  const {
    checkForHiddenListings,
    getBalance,
    getCurrentPaymentAsset,
    getPaymentAssets,
    getMinRaisePrice,
    getMinBid,
    getTotalPrice,
    isEnglishAuction,
    isFungible,
    maxQuantity,
    isCollectionOffer,
    collectionAssetContract,
    eventParams,
  } = useOfferModalAdapter({
    asset,
    collection,
    tradeData,
    tradeLimitsDataKey: tradeLimits,
  })

  const collectionExpirationOptions = useCollectionOfferExpirationOptions()
  const offerExpirationOptions = useOfferExpirationOptions()

  const paymentAssets = getPaymentAssets()
  const defaultOption = isCollectionOffer
    ? DEFAULT_COLLECTION_EXPIRATION_OPTION
    : DEFAULT_EXPIRATION_OPTION
  const [bidExpirationValue, setBidExpirationValue] =
    useState<ExpirationOption["value"]>(defaultOption)

  const defaultMaxOfferDuration = isCollectionOffer
    ? addMonths(new Date(), 1)
    : addMonths(new Date(), 6)

  const [maxOfferDuration, setMaxOfferDuration] = useState(
    defaultMaxOfferDuration,
  )

  const [selectedPaymentAssetRelayId, setSelectedPaymentAssetRelayId] =
    useState<string>(
      () =>
        (isEnglishAuction
          ? paymentAssets.find(
              paymentAsset => paymentAsset.relayId === bestAsk?.payment.relayId,
            )?.relayId
          : paymentAssets[0].relayId) ?? "",
    )

  const expirationOptions = isCollectionOffer
    ? collectionExpirationOptions
    : offerExpirationOptions
  const defaultExpirationDate = expirationOptions.find(
    option => option.value === defaultOption,
  )
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    formState,
    setError,
    trigger,
  } = useForm<OfferModalFormData>({
    mode: "onChange",
    defaultValues: {
      paymentAssetRelayId: selectedPaymentAssetRelayId,
      bidExpiration: isEnglishAuction
        ? add(
            bestAsk?.closedAt ? dateFromISO8601(bestAsk.closedAt) : new Date(),
            { days: 3 },
          )
        : defaultExpirationDate?.date ?? add(new Date(), { minutes: 30 }),
      quantity: initialQuantity?.toString() ?? "1",
      pricePerUnit:
        initialPricePerUnit ??
        (isEnglishAuction &&
        (getMinRaisePrice(selectedPaymentAssetRelayId) ||
          getMinBid(selectedPaymentAssetRelayId))
          ? BigNumber.max(
              getMinRaisePrice(selectedPaymentAssetRelayId) ?? 0,
              getMinBid(selectedPaymentAssetRelayId) ?? 0,
            ).toString()
          : ""),
    },
  })

  const [selectedPaymentAssetBalance, setSelectedPaymentAssetBalance] =
    useState<BigNumber>(bn(0))
  const fetchAndSetBalance = async (paymentAssetId?: string) => {
    const assetId = paymentAssetId ?? selectedPaymentAssetRelayId
    const balance = await getBalance(assetId)
    setSelectedPaymentAssetBalance(balance)
  }

  const pricePerUnitInput = watch("pricePerUnit")
  useUpdateEffect(() => {
    if (pricePerUnitInput !== "") {
      trigger("pricePerUnit")
    }
  }, [selectedPaymentAssetBalance])

  const setHiddenListingWarning = async () => {
    const shouldShowHiddenListingWarning = await checkForHiddenListings()
    if (isMountedRef.current) {
      setShowHiddenListingWarning(shouldShowHiddenListingWarning)
    }
  }

  const quantity = watch("quantity")
  const bidExpiration = watch("bidExpiration")
  const paymentAsset = getCurrentPaymentAsset(selectedPaymentAssetRelayId)
  const usdSpotPrice = paymentAsset.usdPrice
  const symbol = paymentAsset.symbol
  const chain = paymentAsset.chain.identifier
  const roundedPricePerUnit = offerPriceRounding(pricePerUnitInput)
  const pricePerUnit: BigNumber = roundedPricePerUnit
  const totalPrice = getTotalPrice({
    quantity,
    pricePerUnit: pricePerUnit.isNaN() ? "" : pricePerUnit.toString(),
  })
  const totalUsdPrice =
    totalPrice !== null ? bn(totalPrice).times(usdSpotPrice) : null
  const perUnitUsdPrice = !pricePerUnit.isNaN()
    ? pricePerUnit.times(usdSpotPrice)
    : null
  const defaultCustomExpiration = add(new Date(), { minutes: 30 })
  const showAddFunds = selectedPaymentAssetBalance.isLessThan(
    pricePerUnit.times(bn(quantity)),
  )
  const hasFloorPriceWarning =
    formState.errors.pricePerUnit?.type === "floorWarning"

  useMountEffect(() => {
    fetchAndSetBalance()
    setHiddenListingWarning()
    trackOpenOfferModal(eventParams)
  })

  useEffect(() => {
    if (formState.errors.pricePerUnit) {
      // only add these errors if there is no existing error on the field
      return
    }

    const collectionFloor = bn(collection?.statsV2.floorPrice?.usd ?? "0")
    const floorPriceInUsd = selectedTrait
      ? BigNumber.max(bn(selectedTrait.floor?.usd ?? "0"), collectionFloor)
      : collectionFloor

    if (
      isCollectionOffer &&
      perUnitUsdPrice &&
      floorPriceInUsd.isGreaterThan(0) &&
      perUnitUsdPrice.isGreaterThan(floorPriceInUsd)
    ) {
      setError("pricePerUnit", {
        type: "floorWarning",
        message: t(
          "offerModal.collectionOffer.error",
          `Offer is above the floor price for this {{selectedTrait}}.`,
          {
            selectedTrait: selectedTrait
              ? t("offers.trait.description.trait", "trait")
              : t("offers.trait.description.collection", "collection"),
          },
          {
            forceString: true,
          },
        ),
      })
    }
  }, [
    collection?.statsV2.floorPrice?.usd,
    formState.errors.pricePerUnit,
    isCollectionOffer,
    perUnitUsdPrice,
    selectedTrait,
    setError,
    t,
  ])

  const preSaleReactivatableListingsWarning =
    useReactivatableListingsWarningContent({
      variant: "prePurchase",
      isMultipleItems: false, // currently we do not check for reactivatable listings for collection offers
    })

  const handleOrderCreated: BlockchainActionOnEnd = ({ transaction } = {}) => {
    if (transaction?.transactionHash && asset?.relayId) {
      onReplace(
        <AssetSuccessModalContent
          mode="offered"
          transaction={transaction}
          variables={{ assetIDs: [asset.relayId] }}
          onTransactionConfirmed={onOrderCreated}
        />,
      )
    } else {
      const successText = t(
        "offerModal.submitSuccess",
        "Your offer has been submitted.",
      )
      const submitted = t(
        "offerModal.collectionOffer.submitSuccess",
        `Your offer has been submitted. {{link}}`,
        {
          link: (
            <Link href="/account/offers">
              {t("offerModal.offers.link", "View your offers")}
            </Link>
          ),
        },
      )
      onClose()
      showSuccessMessage(
        isCollectionOffer ? <Block>{submitted}</Block> : successText,
      )
      onOrderCreated?.()
    }
  }

  const onSubmit = handleSubmit(async formData => {
    const takerAssetId = asset?.relayId

    trackSubmitOfferModalForm({
      ...eventParams,
      ...formData,
      selectedTrait,
    })

    if (!(takerAssetId || isCollectionOffer)) {
      return
    }

    const onError = () => {
      onClose()
      showErrorMessage(
        t(
          "offerModal.create.error",
          "Something went wrong while creating an offer",
        ),
      )
    }

    const totalPrice = pricePerUnit.times(formData.quantity).toString()
    const priceInput = {
      paymentAsset: paymentAsset.relayId,
      amount: totalPrice,
    }
    const closedAt = bidExpiration.toISOString()

    if (isCollectionOffer && collection?.slug && collectionAssetContract) {
      onNext(
        <CreateCollectionOfferActionModal
          assetContract={collectionAssetContract}
          closedAt={closedAt}
          collection={collection.slug}
          price={priceInput}
          quantity={quantity}
          trait={
            selectedTrait
              ? {
                  name: selectedTrait.key,
                  value: selectedTrait.value,
                }
              : undefined
          }
          onEnd={handleOrderCreated}
          onError={onError}
        />,
      )
    } else if (asset) {
      const privateListingAddress = asset.tradeSummary.bestAsk?.taker?.address
      const isCurrentUsersPrivateListing =
        privateListingAddress === connectedAddress

      const assetListingPriceUsd =
        asset.tradeSummary.bestAsk?.perUnitPriceType.usd || ""

      const isUserPriceMoreThanListedPrice =
        assetListingPriceUsd &&
        perUnitUsdPrice &&
        bn(perUnitUsdPrice).isGreaterThan(bn(assetListingPriceUsd))

      const showAboveListingPriceWarningModal = privateListingAddress
        ? isCurrentUsersPrivateListing && isUserPriceMoreThanListedPrice
        : isUserPriceMoreThanListedPrice

      // No need to show if English Auction as price is meant to be higher
      if (showAboveListingPriceWarningModal && !isEnglishAuction) {
        onNext(
          <AboveListingPriceWarningModal
            perUnitListingPrice={
              asset.tradeSummary.bestAsk?.perUnitPriceType || null
            }
            perUnitUsdPrice={perUnitUsdPrice}
            onClose={onClose}
            onConfirm={() => {
              onNext(
                <CreateOfferActionModal
                  closedAt={closedAt}
                  isEnglishAuction={isEnglishAuction}
                  item={{ asset: asset.relayId, quantity }}
                  price={priceInput}
                  onEnd={handleOrderCreated}
                  onError={onError}
                />,
              )
            }}
          />,
        )
        return
      }

      onNext(
        <CreateOfferActionModal
          closedAt={closedAt}
          isEnglishAuction={isEnglishAuction}
          item={{ asset: asset.relayId, quantity }}
          price={priceInput}
          onEnd={handleOrderCreated}
          onError={onError}
        />,
      )
    }
  })

  const paymentAssetOptions = getPaymentAssetOptions(paymentAssets)

  const onSelectTrait = useCallback(
    (selection?: SelectedTrait) => {
      if (!selection) {
        setSelectedTrait(null)
        setMaxOfferDuration(defaultMaxOfferDuration)
        setValue("bidExpiration", defaultCustomExpiration)
        return
      }
      trackOfferModalTraitSelected({
        ...eventParams,
        selectedTrait: selection,
      })
      setSelectedTrait(selection)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [eventParams, setValue],
  )

  const renderPriceSide = useCallback(
    () => (
      <>
        <Item.Title>
          {totalPrice ?? EMPTY_PRICE_DISPLAY} {symbol}
        </Item.Title>
        <Item.Description>
          {totalUsdPrice && !totalUsdPrice.isNaN()
            ? `$${displayUSD(totalUsdPrice)}`
            : EMPTY_PRICE_DISPLAY}
        </Item.Description>
      </>
    ),
    [symbol, totalPrice, totalUsdPrice],
  )

  return (
    <Modal.Form
      autoComplete="off"
      maxHeight="100vh"
      maxWidth="100vw"
      onSubmit={onSubmit}
    >
      <Modal.Header>
        <Modal.Header.Title>
          <Flex>
            {isEnglishAuction
              ? t("offerModal.placeBid", "Place a bid")
              : isCollectionOffer
              ? t(
                  "offerModal.makeOffer.collection.title",
                  "Make a collection offer",
                )
              : t("offerModal.makeOffer.title", "Make an offer")}
            {isCollectionOffer && (
              <InfoIcon
                overrides={{
                  Button: { style: { marginLeft: "8px" } },
                  Icon: { size: 16 },
                  Tooltip: { placement: "bottom" },
                }}
                tooltipContent={
                  <>
                    {selectedTrait
                      ? t(
                          "offerModal.tooltipSelectedTrait",
                          "This offer can be accepted once for any single item in this collection with the selected trait.",
                        )
                      : t(
                          "orders.offerModal.tooltipNoSelectedTrait",
                          "A collection offer can be accepted once for any single item in this collection.",
                        )}
                    <br />
                    <Link href="https://support.opensea.io/articles/8866980">
                      {t("offerModal.learnMore", "Learn more")}
                    </Link>
                  </>
                }
              />
            )}
          </Flex>
        </Modal.Header.Title>

        {isCollectionOffer && collection ? (
          <CollectionOfferDetails
            className="pt-6"
            collection={collection}
            quantity={Number(quantity)}
            renderPriceSide={renderPriceSide}
            trait={selectedTrait ?? undefined}
          />
        ) : (
          <ItemOfferDetails
            className="pt-6"
            item={asset}
            quantity={Number(quantity)}
            renderPriceSide={renderPriceSide}
          />
        )}
      </Modal.Header>

      <Modal.Body
        padding={{
          _: `0 ${MOBILE_MODAL_PADDING}px ${MOBILE_MODAL_PADDING}px ${MOBILE_MODAL_PADDING}px`,
          sm: `0 ${MODAL_PADDING}px 0 ${MODAL_PADDING}px`,
        }}
      >
        {showHiddenListingWarning && (
          <Alert className="mb-2">
            <Alert.Icon color="warning" value="error" />
            <Alert.Title>{preSaleReactivatableListingsWarning}</Alert.Title>
          </Alert>
        )}
        <Block>
          {collection?.isTraitOffersEnabled && (
            <Block marginBottom="16px">
              <LazyTraitSelector
                collectionSlug={collection.slug}
                overrides={{
                  Input: {
                    placeholder: t(
                      "offerModal.traitSelector.placeholder",
                      "Choose a trait",
                    ),
                  },
                  FormControl: {
                    label: t(
                      "offerModal.traitSelector.placeholder",
                      "Choose a trait",
                    ),
                    hideLabel: true,
                  },
                }}
                renderEmptyState
                selectedTrait={selectedTrait}
                onSelectTrait={onSelectTrait}
              />
            </Block>
          )}

          <Block marginBottom="16px">
            <ContextualPriceList
              balance={selectedPaymentAssetBalance}
              balanceSymbol={paymentAsset.symbol}
              collection={collection ?? asset?.collection ?? null}
              selectedTrait={selectedTrait}
              tradeSummary={tradeData}
            />
          </Block>

          <Flex className="relative mb-2 gap-2">
            <Block flex={1}>
              <Controller
                control={control}
                name="pricePerUnit"
                render={({ field }) => {
                  return (
                    <PaymentTokenInput
                      captionRight={
                        hasBiddingBalance(chain) ? (
                          <>
                            {t(
                              "orders.offerModal.offerBalance.text",
                              `{{symbol}} will be added to your {{offerLink}}.`,
                              {
                                offerLink: (
                                  <Link href={SOLANA_OFFER_BALANCE_ARTICLE}>
                                    {t(
                                      "offerModal.offerBalance.link",
                                      "offer balance",
                                    )}
                                  </Link>
                                ),
                                symbol: paymentAsset.symbol,
                              },
                            )}
                          </>
                        ) : isCollectionOffer ? (
                          perUnitUsdPrice ? (
                            `$${displayUSD(perUnitUsdPrice)}`
                          ) : (
                            ""
                          )
                        ) : (
                          t(
                            "offerModal.totalOfferAmount",
                            "Total offer amount: {{totalPrice}} {{symbol}} {{totalUsdPrice}}",
                            {
                              totalPrice: totalPrice || 0,
                              symbol,
                              totalUsdPrice: totalUsdPrice
                                ? `($${displayUSD(totalUsdPrice)})`
                                : "",
                            },
                            { forceString: true },
                          )
                        )
                      }
                      error={
                        !hasFloorPriceWarning
                          ? formState.errors.pricePerUnit
                          : undefined
                      }
                      hideLabel
                      label={t("offerModal.form.offerAmount.label", "Price")}
                      name={field.name}
                      paymentAssetOptions={paymentAssetOptions}
                      paymentAssetRelayId={selectedPaymentAssetRelayId}
                      placeholder={t(
                        "offerModal.form.offerAmount.placeholder",
                        "Price",
                      )}
                      price={field.value}
                      quantity={Number(quantity)}
                      warning={
                        hasFloorPriceWarning
                          ? formState.errors.pricePerUnit?.message
                          : undefined
                      }
                      onChange={price => {
                        const value = price.replace(",", ".")
                        field.onChange(value)
                      }}
                      onChangePaymentAsset={relayId => {
                        setSelectedPaymentAssetRelayId(relayId)
                        fetchAndSetBalance(relayId)
                      }}
                    />
                  )
                }}
                rules={{
                  required: true,
                  validate: {
                    pricePrecision: value => {
                      const decimalPlaces =
                        value.toString().split(".")[1]?.length || 0
                      const maxAllowedDecimals = isNewOfferPrecisionRequired
                        ? getOfferPricePrecision(bn(value))
                        : MAX_DECIMALS_FOR_OFFERS
                      if (decimalPlaces > maxAllowedDecimals) {
                        return t(
                          "offerModal.form.pricePrecision.error",
                          `Maximum {{decimals}} decimals places allowed`,
                          { decimals: maxAllowedDecimals },
                          { forceString: true },
                        )
                      }
                      return true
                    },
                    insufficientFunds: value => {
                      console.log({
                        selectedPaymentAssetBalance:
                          selectedPaymentAssetBalance.toString(),
                        quantity,
                        value,
                      })
                      // We only gate funds on Ethereum as other chains have the ability to deposit/bridge assets, but on
                      // Ethereum we don't
                      const insufficientFunds =
                        chain === getEthereumChain()
                          ? selectedPaymentAssetBalance.isLessThan(
                              bn(value).times(bn(quantity)),
                            )
                          : false

                      if (insufficientFunds) {
                        return t(
                          "offerModal.form.insufficientFunds.error",
                          `You don't have enough {{symbol}}`,
                          { symbol },
                          { forceString: true },
                        )
                      }

                      return true
                    },
                    other: value => {
                      const minRaisePrice = getMinRaisePrice(
                        selectedPaymentAssetRelayId,
                      )
                      const minBidPrice = getMinBid(selectedPaymentAssetRelayId)
                      const perUnitPrice = bn(value)
                      const minimumPriceInUsd = tradeLimits.minimumBidUsdPrice

                      if (
                        perUnitPrice.isNaN() ||
                        !isValidNumericInput(value, paymentAsset.decimals)
                      ) {
                        return t(
                          "offerModal.form.validAmount.error",
                          "Please enter a valid amount.",
                        )
                      }

                      if (
                        isEnglishAuction &&
                        minRaisePrice &&
                        perUnitPrice.isLessThan(minRaisePrice)
                      ) {
                        return t(
                          "offerModal.form.error.bidTooLow",
                          `Place a bid of at least {{minRaisePrice}} {{symbol}} to become the highest bidder`,
                          {
                            minRaisePrice: minRaisePrice.toString(),
                            symbol,
                          },
                          { forceString: true },
                        )
                      }

                      if (minBidPrice && perUnitPrice.isLessThan(minBidPrice)) {
                        if (isEnglishAuction) {
                          if (isFungible) {
                            return t(
                              "offerModal.form.fungible.englishMinPrice",
                              "Offer must be at least the minimum price per unit of {{minBidPrice}} {{symbol}}",
                              {
                                minBidPrice: minBidPrice.toString(),
                                symbol,
                              },
                              { forceString: true },
                            )
                          }
                          return t(
                            "offerModal.form.englishMinPrice",
                            "Offer must be at least the minimum price of {{minBidPrice}} {{symbol}}",
                            {
                              minBidPrice: minBidPrice.toString(),
                              symbol,
                            },
                            { forceString: true },
                          )
                        }
                        if (minimumPriceInUsd) {
                          if (isFungible) {
                            return t(
                              "offerModal.form.fungible.minPrice",
                              "Offer must be at least the minimum price per unit of ${{minPriceUSD}} USD ({{minPrice}} {{symbol}})",
                              {
                                minPriceUSD: displayUSD(minimumPriceInUsd),
                                minPrice: display(minBidPrice, symbol),
                                symbol,
                              },
                              { forceString: true },
                            )
                          }
                          return t(
                            "offerModal.form.minPrice",
                            "Offer must be at least the minimum price of ${{minPriceUSD}} USD ({{minPrice}} {{symbol}})",
                            {
                              minPriceUSD: displayUSD(minimumPriceInUsd),
                              minPrice: display(minBidPrice, symbol),
                              symbol,
                            },
                            { forceString: true },
                          )
                        }
                      }

                      return true
                    },
                  },
                }}
              />
            </Block>
            {formState.errors.pricePerUnit?.type === "insufficientFunds" && (
              <Button
                style={{ blockSize: 48 }}
                icon="refresh"
                variant="secondary"
                onClick={() => fetchAndSetBalance()}
              />
            )}
          </Flex>

          {isFungible || isCollectionOffer ? (
            <Form.Control
              error={formState.errors.quantity?.message}
              hideLabel
              // The actual label is below
              label={undefined}
            >
              <Item variant="unstyled">
                <Item.Content>
                  <Item.Title>
                    <Text weight="semibold">
                      <Label htmlFor="quantity">
                        {isCollectionOffer
                          ? t("offerModal.numOffers", "# of offers")
                          : t("offerModal.quantity", "Quantity")}
                      </Label>
                    </Text>
                  </Item.Title>
                  <Item.Description>
                    {isCollectionOffer
                      ? t(
                          "offerModal.acceptedSeparately",
                          "Offers can be accepted separately",
                        )
                      : t(
                          "offerModal.availableQuantity",
                          "{{totalQuantity}} available",
                          { totalQuantity: display(maxQuantity) },
                        )}
                  </Item.Description>
                </Item.Content>

                <Item.Side>
                  <Input
                    {...register("quantity", {
                      required: t(
                        "offerModal.form.quantityRequired.error",
                        "Quantity is required",
                      ),
                      min: {
                        value: 1,
                        message: t(
                          "offerModal.form.min.error",
                          "Please enter a valid quantity",
                        ),
                      },
                      max: {
                        value: maxQuantity.toNumber(),
                        message: isCollectionOffer
                          ? t(
                              "offerModal.collectionOfferQuantity",
                              "Maximum of {{maximumCollectionOffers}} offers",
                              {
                                maximumCollectionOffers:
                                  MAX_COLLECTION_OFFER_QUANTITY,
                              },
                              { forceString: true },
                            )
                          : t(
                              "offerModal.form.max.error",
                              "Please enter a valid quantity",
                            ),
                      },
                    })}
                    endEnhancer={
                      <UnstyledButton
                        disabled={bn(quantity).gte(maxQuantity)}
                        onClick={() => {
                          setValue(
                            "quantity",
                            bn(quantity).plus(1).toString(),
                            { shouldValidate: true },
                          )
                        }}
                      >
                        <InteractiveIcon size={16} value="add" />
                      </UnstyledButton>
                    }
                    overrides={{
                      Input: { style: { textAlign: "center" } },
                    }}
                    startEnhancer={
                      <UnstyledButton
                        disabled={bn(quantity).lte(0)}
                        onClick={() => {
                          setValue(
                            "quantity",
                            bn(quantity).minus(1).toString(),
                            { shouldValidate: true },
                          )
                        }}
                      >
                        <InteractiveIcon size={16} value="remove" />
                      </UnstyledButton>
                    }
                    type="number"
                    width="136px"
                  />
                </Item.Side>
              </Item>
            </Form.Control>
          ) : null}

          {!isEnglishAuction ? (
            <Form.Control
              label={t("offerModal.labelDuration.label", "Duration")}
            >
              <Flex>
                <Block marginRight="8px">
                  <Controller
                    control={control}
                    name="bidExpiration"
                    render={({ field }) => {
                      return (
                        <Select
                          clearable={false}
                          options={expirationOptions}
                          overrides={{
                            Dropdown: {
                              props: {
                                placement: "bottom",
                                popperOptions: {
                                  modifiers: [
                                    {
                                      name: "flip",
                                      enabled: true,
                                    },
                                  ],
                                },
                              },
                            },
                          }}
                          readOnly
                          style={{ width: "162px" }}
                          value={bidExpirationValue}
                          onSelect={option => {
                            if (option) {
                              setBidExpirationValue(option.value)
                              field.onChange(
                                option.date ?? defaultCustomExpiration,
                              )
                            }
                          }}
                        />
                      )
                    }}
                  />
                </Block>
                <Block flexGrow={1} minWidth={0}>
                  <DatePicker
                    max={maxOfferDuration}
                    min={defaultCustomExpiration}
                    overrides={{
                      Popover: {
                        placement: "bottom",
                        popperOptions: {
                          modifiers: [
                            {
                              name: "flip",
                              enabled: true,
                            },
                          ],
                        },
                      },
                    }}
                    value={bidExpiration}
                    withTime
                    onChange={value => {
                      setValue("bidExpiration", value)
                    }}
                  />
                </Block>
              </Flex>
            </Form.Control>
          ) : null}
        </Block>
        {isCollectionOffer && offerProtectionEnabled && (
          <Alert className="mt-6">
            <Alert.Icon color="blue-3" value="security" />
            <Alert.Title>
              {t(
                "orders.offerModal.protection.body",
                "Your offers are protected from items flagged as stolen and items transferred recently. {{learnMoreLink}}",
                {
                  learnMoreLink: (
                    <Link href="https://support.opensea.io/articles/8866980">
                      {t(
                        "orders.offerModal.protection.learnMoreLink",
                        "Learn more",
                      )}
                    </Link>
                  ),
                },
              )}
            </Alert.Title>
          </Alert>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Button
          disabled={
            // Don't disable the button if the only error is the floor price warning
            (!formState.isValid && !hasFloorPriceWarning) ||
            bn(totalPrice ?? 0).isLessThanOrEqualTo(0)
          }
          type="submit"
        >
          {isEnglishAuction
            ? t("orders.offerModal.placeBid", "Place bid")
            : t("orders.offerModal.makeOffer", "Make offer")}
        </Modal.Footer.Button>

        {showAddFunds && symbol && (
          <Modal.Footer.Button
            variant="secondary"
            onClick={() => {
              onNext(
                <AddFundsModalContent
                  chain={chain}
                  fundsToAdd={totalUsdPrice ?? undefined}
                  requiredAssetAmount={
                    totalPrice
                      ? bn(totalPrice.toString()).minus(
                          selectedPaymentAssetBalance,
                        )
                      : undefined
                  }
                  symbol={symbol}
                  onFundsAdded={() =>
                    onReplace(
                      <OfferModal
                        account={accountDataKey}
                        asset={assetDataKey}
                        collection={collectionDataKey}
                        tradeDataKey={tradeDataKey}
                        tradeLimitsDataKey={tradeLimitsDataKey}
                        onClose={onClose}
                        onOrderCreated={onOrderCreated}
                      />,
                    )
                  }
                />,
              )
            }}
          >
            {symbol === "WETH"
              ? t("offerModal.addWETH", "Add WETH")
              : t("offerModal.addFunds", "Add funds")}
          </Modal.Footer.Button>
        )}
      </Modal.Footer>
    </Modal.Form>
  )
}

const InteractiveIcon = styled(Icon)`
  ${interactiveStylesPrimary};
`
