/**
 * @generated SignedSource<<374ca4774b92edad30d4fcc55202252e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AboveListingPriceWarningModal_pricing$data = {
  readonly symbol: string;
  readonly unit: string;
  readonly usd: string;
  readonly " $fragmentType": "AboveListingPriceWarningModal_pricing";
};
export type AboveListingPriceWarningModal_pricing$key = {
  readonly " $data"?: AboveListingPriceWarningModal_pricing$data;
  readonly " $fragmentSpreads": FragmentRefs<"AboveListingPriceWarningModal_pricing">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AboveListingPriceWarningModal_pricing",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    }
  ],
  "type": "PriceType",
  "abstractKey": null
};

(node as any).hash = "3f8eb2719a8ee1e21de13e2d781b274c";

export default node;
