import React, { useCallback, useEffect, useRef, useState } from "react"
import {
  Input,
  InputProps,
  CenterAligned,
  Text,
  Skeleton,
} from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/nextjs"
import { useFragment, useLazyLoadQuery } from "react-relay"
import { useClickAway, useDebounce, usePreviousDistinct } from "react-use"
import { SsrSuspense } from "@/components/common/SsrSuspense.react"
import { Block } from "@/design-system/Block"
import { Dropdown, RenderDropdownContentProps } from "@/design-system/Dropdown"
import { FormControl, FormControlProps } from "@/design-system/FormControl"
import { ListProps } from "@/design-system/List"
import { useIsTraitSearchFloorPriceEnabled } from "@/hooks/useFlag"
import { useIsOpen } from "@/hooks/useIsOpen"
import { useTranslate } from "@/hooks/useTranslate"
import { TraitSelector_data$key } from "@/lib/graphql/__generated__/TraitSelector_data.graphql"
import { TraitSelectorQuery } from "@/lib/graphql/__generated__/TraitSelectorQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { quantityDisplay } from "@/lib/helpers/numberUtils"
import { TraitSelectorSection } from "./TraitSelectorSection.react"
import { SelectedTrait, Trait, TraitValue } from "./types"

const DROPDOWN_CONTENT_HEIGHT = "250px"

export type TraitSelectorProps = {
  dataKey: TraitSelector_data$key
  onSelectTrait: (selection: SelectedTrait) => unknown
  selectedTrait?: SelectedTrait
  variant?: "input-only" | "form"
  overrides?: {
    List?: Omit<ListProps, "height"> & { height: string } // height must be string, ints and responsive style not supported
    Input?: InputProps
    FormControl?: Omit<FormControlProps, "children">
  }
  renderEmptyState?: boolean
  /**
   * Called only if a trait is not selected as a result of the enter keypress
   */
  onEnter?: (value: string) => unknown
  onClickAway?: () => unknown
  showTraitFloor?: boolean
  showItemCount?: boolean
}

export const TraitSelector = ({
  dataKey,
  selectedTrait,
  onSelectTrait,
  variant = "form",
  overrides = {},
  renderEmptyState,
  onEnter,
  onClickAway,
  showTraitFloor = true,
  showItemCount = true,
}: TraitSelectorProps) => {
  const t = useTranslate("components")
  const { isOpen, open: openDropdown, close: closeDropdown } = useIsOpen()
  const [searchQuery, setSearchQuery] = useState("")
  const searchInputRef = useRef<HTMLInputElement>(null)
  const resultsContainerRef = useRef<HTMLUListElement>(null)
  const {
    statsV2: { totalSupply: assetCount },
    // TODO(robert): Figure out why this is undefined in stories i.e. CollectionPageSubscriptions
    // Do this workaround to default it to empty array
    stringTraits = [],
  } = useFragment(
    graphql`
      fragment TraitSelector_data on CollectionType
      @argumentDefinitions(
        withTraitFloor: { type: "Boolean", defaultValue: true }
      ) {
        statsV2 {
          totalSupply
        }
        stringTraits(withTraitFloor: $withTraitFloor) {
          key
          counts {
            count
            value
            floor {
              eth
              unit
              symbol
              usd
            }
          }
        }
      }
    `,
    dataKey,
  )

  const dropdownContentHeight =
    overrides.List?.height ?? DROPDOWN_CONTENT_HEIGHT

  const previousSearchQuery = usePreviousDistinct(searchQuery)
  const [filteredTraits, setFilteredTraits] = useState<Trait[]>([])
  useDebounce(
    () => {
      if (searchQuery.trim() === "" || !!selectedTrait) {
        setFilteredTraits([...stringTraits])
        return
      }

      let traits = stringTraits
      // optimization to only filter previous results if previous search term is substring of current search term
      if (
        previousSearchQuery?.length &&
        searchQuery.includes(previousSearchQuery)
      ) {
        traits = filteredTraits
      }

      const results: Array<Trait> = []

      traits.forEach((trait: Trait) => {
        if (trait.key.toLowerCase().includes(searchQuery.toLowerCase())) {
          results.push(trait)
          return
        }

        const matchingCounts = trait.counts.filter((traitValue: TraitValue) =>
          traitValue.value.toLowerCase().includes(searchQuery.toLowerCase()),
        )
        if (matchingCounts.length) {
          results.push({
            key: trait.key,
            counts: matchingCounts,
          })
        }
      })

      setFilteredTraits(results)
    },
    200,
    [searchQuery, stringTraits],
  )

  const handleSelectTrait = useCallback(
    (selection: SelectedTrait) => {
      if (
        selection &&
        (selectedTrait?.key !== selection.key ||
          selectedTrait.value !== selection.value)
      ) {
        onSelectTrait(selection)
      }
    },
    [selectedTrait, onSelectTrait],
  )

  const renderDropdownContent = useCallback(
    ({ List }: RenderDropdownContentProps) => {
      const onSelect = (selection: SelectedTrait) => {
        handleSelectTrait(selection)
        closeDropdown()
      }

      if (!filteredTraits.length && !renderEmptyState) {
        return null
      }

      const { height: _height, ...listOverrides } = overrides.List ?? {}

      return (
        <List
          className="w-full"
          ref={resultsContainerRef}
          style={{ height: dropdownContentHeight }}
          variant="condensed"
          {...listOverrides}
        >
          <List.Header>
            <List.Header.Title>
              {t("traitSelector.trait", "Trait")}
            </List.Header.Title>
            {showTraitFloor && (
              <List.Header.Title>
                {t("traitSelector.floor", "Floor")}
              </List.Header.Title>
            )}
          </List.Header>
          {filteredTraits.length ? (
            filteredTraits.map((trait: Trait) => (
              <TraitSelectorSection
                key={trait.key}
                showTraitFloor={showTraitFloor}
                totalAssetCount={assetCount}
                trait={trait}
                onSelect={onSelect}
              />
            ))
          ) : (
            <CenterAligned className="py-6">
              <Text asChild color="secondary" size="tiny" weight="semibold">
                <p>
                  {t(
                    "traitSelector.noMatches",
                    "No traits matching your search",
                  )}
                </p>
              </Text>
            </CenterAligned>
          )}
        </List>
      )
    },
    // Allow overrides to change without creating a new callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filteredTraits,
      renderEmptyState,
      dropdownContentHeight,
      handleSelectTrait,
      closeDropdown,
      assetCount,
    ],
  )

  useEffect(() => {
    setSearchQuery(
      selectedTrait
        ? `${selectedTrait.key}: ${selectedTrait.value}`
        : searchQuery,
    )
  }, [selectedTrait, searchQuery])

  useClickAway(searchInputRef, e => {
    const node = e.target as HTMLElement
    if (resultsContainerRef.current?.contains(node)) {
      return
    }
    if (isOpen) {
      closeDropdown()
      onClickAway?.()
    }
  })

  // The ENS collection has too many traits, resutling in resolver
  //   timeoutes and undefined string traits
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if ((!stringTraits || !stringTraits.length) && variant === "form") {
    return null
  }

  const handleEnter = () => {
    if (!searchQuery) {
      return
    }

    // get the first element and select it (or pass undefined if not available)
    const trait = filteredTraits.find(x => x.counts.length > 0)

    if (trait) {
      onSelectTrait({
        key: trait.key,
        value: trait.counts[0].value,
        floor: trait.counts[0].floor,
        count: trait.counts[0].count,
      })
    } else {
      onEnter?.(searchQuery)
    }
    closeDropdown()
  }

  const itemCount = selectedTrait ? selectedTrait.count : assetCount

  const content = (
    <Block flex={1}>
      <Dropdown
        content={renderDropdownContent}
        hideOnClick
        matchReferenceWidth
        maxHeight={dropdownContentHeight}
        maxWidth="100%"
        offset={[0, 0]}
        visible={isOpen}
      >
        <Input
          aria-controls="CollectionTraitSearch--results"
          clearable={!!selectedTrait}
          id="trait-selector-input"
          inputRef={searchInputRef}
          placeholder={t("traitSelector.allTraits", "All traits")}
          readOnly={!!selectedTrait}
          role="searchbox"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value)
            if (!e.target.value && selectedTrait) {
              onSelectTrait(null)
              openDropdown()
            }
          }}
          onEnter={handleEnter}
          onFocus={openDropdown}
          {...overrides.Input}
        />
      </Dropdown>
    </Block>
  )

  if (variant === "form") {
    return (
      <FormControl
        captionLeft={
          showItemCount
            ? `${quantityDisplay(itemCount)} ${t(
                "traitSelector.items",
                { 0: "items", one: "item", other: "items" },
                { count: itemCount },
                { forceString: true },
              )}`
            : undefined
        }
        htmlFor="trait-selector-input"
        label={t("traitSelector.chooseTrait", "Choose trait")}
        {...overrides.FormControl}
      >
        <Block
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-owns="CollectionTraitSearch--results"
          role="combobox"
        >
          {content}
        </Block>
      </FormControl>
    )
  }

  return content
}

type LazyTraitSelectorProps = Omit<TraitSelectorProps, "dataKey"> & {
  collectionSlug: string
}
export const LazyTraitSelectorBase = ({
  collectionSlug,
  ...props
}: LazyTraitSelectorProps) => {
  const withTraitFloor = useIsTraitSearchFloorPriceEnabled(collectionSlug)

  const data = useLazyLoadQuery<TraitSelectorQuery>(
    graphql`
      query TraitSelectorQuery(
        $collectionSlug: CollectionSlug
        $withTraitFloor: Boolean
      ) {
        collection(collection: $collectionSlug) {
          ...TraitSelector_data @arguments(withTraitFloor: $withTraitFloor)
        }
      }
    `,
    { collectionSlug, withTraitFloor },
  )

  if (!data.collection) {
    return <></>
  }

  return (
    <TraitSelector
      {...props}
      dataKey={data.collection}
      showTraitFloor={withTraitFloor}
    />
  )
}

export const LazyTraitSelector = (props: LazyTraitSelectorProps) => {
  return (
    <ErrorBoundary fallback={<></>}>
      <SsrSuspense
        fallback={<Skeleton.Block className="h-12 rounded-default" />}
      >
        <LazyTraitSelectorBase {...props} />
      </SsrSuspense>
    </ErrorBoundary>
  )
}
