import React from "react"
import { Link } from "@/components/common/Link"
import { useTranslate } from "@/hooks/useTranslate"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { ReactivatableListingsWarningVariant } from "./types"

type UseReactivatableListingsWarningContentProps = {
  variant: ReactivatableListingsWarningVariant
  isMultipleItems: boolean
}

export const useReactivatableListingsWarningContent = ({
  variant,
  isMultipleItems,
}: UseReactivatableListingsWarningContentProps): React.ReactNode => {
  const t = useTranslate("assets")

  const i18nItemCount = isMultipleItems ? 2 : 1 // only used for translation context (not displayed)

  const manageListingsLink = (
    <Link href="/account/listings">
      {t("listingWarning.manageListings", "Manage listings")}
    </Link>
  )

  const manageInactiveListingsLink = (
    <Link href="/account/listings">
      {t("listingWarning.manageInactiveListings", "Manage inactive listings")}
    </Link>
  )

  const inactiveListingsLearnMoreLink = (
    <Link href="https://support.opensea.io/articles/8867019">
      {t("listingWarning.learnMore", "Learn more")}
    </Link>
  )

  switch (variant) {
    case "preTransfer":
      return t(
        "listingWarning.preTransferPrompt",
        {
          "0": "The destination wallet has listings for one or more of these items that will be reactivated on transfer. Alert the wallet owner to cancel those listings. {{inactiveListingsLearnMoreLink}}",
          one: "The destination wallet has listings for this item that will be reactivated on transfer. Alert the wallet owner to cancel those listings. {{inactiveListingsLearnMoreLink}}",
          other:
            "The destination wallet has listings for one or more of these items that will be reactivated on transfer. Alert the wallet owner to cancel those listings. {{inactiveListingsLearnMoreLink}}",
        },
        { count: i18nItemCount, inactiveListingsLearnMoreLink },
      )
    case "postTransfer":
      return t(
        "listingWarning.postTransferPrompt",
        {
          "0": "You have listings for one or more of these items that will be reactivated if transferred back to this wallet. {{manageListingsLink}}",
          one: "You have listings for this item that will reactivate if the item is transferred back to this wallet. {{manageListingsLink}}",
          other:
            "You have listings for one or more of these items that will reactivate if the item is transferred back to this wallet. {{manageListingsLink}}",
        },
        { count: i18nItemCount, manageListingsLink },
      )
    case "prePurchase":
      return t(
        "listingWarning.prePurchasePrompt",
        {
          "0": "You have inactive listings for one or more of these items that will reactivate upon purchase. {{manageInactiveListingsLink}}",
          one: "You have inactive listings for this item that will reactivate upon purchase. {{manageInactiveListingsLink}}",
          other:
            "You have inactive listings for one or more of these items that will reactivate upon purchase. {{manageInactiveListingsLink}}",
        },
        { count: i18nItemCount, manageInactiveListingsLink },
      )
    default:
      throw new UnreachableCaseError(variant)
  }
}
