/**
 * @generated SignedSource<<925de08903956b2874304fa08310c342>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TraitSelector_data$data = {
  readonly statsV2: {
    readonly totalSupply: number;
  };
  readonly stringTraits: ReadonlyArray<{
    readonly counts: ReadonlyArray<{
      readonly count: number;
      readonly floor: {
        readonly eth: string;
        readonly symbol: string;
        readonly unit: string;
        readonly usd: string;
      } | null;
      readonly value: string;
    }>;
    readonly key: string;
  }>;
  readonly " $fragmentType": "TraitSelector_data";
};
export type TraitSelector_data$key = {
  readonly " $data"?: TraitSelector_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"TraitSelector_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": true,
      "kind": "LocalArgument",
      "name": "withTraitFloor"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TraitSelector_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CollectionStatsV2Type",
      "kind": "LinkedField",
      "name": "statsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalSupply",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "withTraitFloor",
          "variableName": "withTraitFloor"
        }
      ],
      "concreteType": "StringTraitType",
      "kind": "LinkedField",
      "name": "stringTraits",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StringTraitCountType",
          "kind": "LinkedField",
          "name": "counts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "count",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "floor",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "eth",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "dd0a01a752e4159fc6e372f29c297c30";

export default node;
