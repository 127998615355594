/* eslint-disable relay/unused-fields */
/* eslint-disable relay/must-colocate-fragment-spreads */
import { useCallback, useMemo } from "react"
import { graphql, useFragment } from "react-relay"
import {
  useConnectedAddress,
  useWallet,
} from "@/containers/WalletProvider/WalletProvider.react"
import { OfferModalEventParams } from "@/features/orders/components/OfferModal/analytics"
import { RAISE_PRICE_COEFFICIENT } from "@/features/orders/constants"
import { useChains } from "@/hooks/useChains"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useOfferModalAdapter_asset$key } from "@/lib/graphql/__generated__/useOfferModalAdapter_asset.graphql"
import {
  useOfferModalAdapter_collection$data,
  useOfferModalAdapter_collection$key,
} from "@/lib/graphql/__generated__/useOfferModalAdapter_collection.graphql"
import { useOfferModalAdapter_tradeData$key } from "@/lib/graphql/__generated__/useOfferModalAdapter_tradeData.graphql"
import { useOfferModalAdapter_tradeLimits$key } from "@/lib/graphql/__generated__/useOfferModalAdapter_tradeLimits.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { BigNumber, bn, offerPriceRounding } from "@/lib/helpers/numberUtils"
import { checkWillReactivateListings } from "@/lib/helpers/orders"
import { readPrice } from "@/lib/helpers/price"

type OfferModalAdapterProps = {
  asset: useOfferModalAdapter_asset$key | null
  collection: useOfferModalAdapter_collection$key | null
  tradeData: useOfferModalAdapter_tradeData$key | null
  tradeLimitsDataKey: useOfferModalAdapter_tradeLimits$key
}
export const MAX_COLLECTION_OFFER_QUANTITY = "100"

export const useOfferModalAdapter = ({
  asset: assetKey,
  collection: collectionKey,
  tradeData: tradeDataKey,
  tradeLimitsDataKey,
}: OfferModalAdapterProps) => {
  const { wallet } = useWallet()
  const toAddress = useConnectedAddress()
  const { isEvmChain } = useChains()

  const tradeData = useFragment(
    graphql`
      fragment useOfferModalAdapter_tradeData on TradeSummaryType {
        bestAsk {
          orderType
          relayId
          item {
            verificationStatus
          }
          payment {
            relayId
          }
          perUnitPriceType {
            unit
          }
        }
        bestBid {
          relayId
          payment {
            relayId
          }
          ...price
        }
      }
    `,
    tradeDataKey,
  )

  const tradeLimits = useFragment(
    graphql`
      fragment useOfferModalAdapter_tradeLimits on TradeLimitsType {
        minimumBidUsdPrice
      }
    `,
    tradeLimitsDataKey,
  )

  const asset = useFragment(
    graphql`
      fragment useOfferModalAdapter_asset on AssetType
      @argumentDefinitions(chain: { type: "ChainScalar" }) {
        relayId
        tokenId
        verificationStatus
        totalQuantity
        decimals
        chain {
          identifier
        }
        assetContract {
          address
        }
        collection {
          paymentAssets(chain: $chain) {
            relayId
            symbol
            chain {
              identifier
            }
            ethPrice
            usdPrice
            decimals
            isNative
            ...utils_PaymentAssetOption
          }
        }
      }
    `,
    assetKey,
  )

  const collection = useFragment(
    graphql`
      fragment useOfferModalAdapter_collection on CollectionType {
        relayId
        slug
        paymentAssets {
          relayId
          symbol
          chain {
            identifier
          }
          ethPrice
          usdPrice
          decimals
          isNative
          ...utils_PaymentAssetOption
        }
        representativeAsset {
          assetContract {
            address
            chain
          }
        }
        assetContracts(first: 2) {
          edges {
            node {
              address
              chain
            }
          }
        }
      }
    `,
    collectionKey,
  )

  const isEnglishAuction = tradeData?.bestAsk?.orderType === "ENGLISH"
  const isCollectionOffer = !!collection

  const getCollectionAssetContracts = (
    collection: useOfferModalAdapter_collection$data | null,
  ) => {
    const assetContracts = getNodes(collection?.assetContracts)
    if (assetContracts.length) {
      return assetContracts
    }
    // Shared storefront do not have a collection-level asset contract.
    return collection?.representativeAsset?.assetContract
      ? [collection.representativeAsset.assetContract]
      : []
  }

  const isOfferablePaymentAsset = useCallback(
    (isNative: boolean, chain: ChainIdentifier) => {
      return isEvmChain(chain) ? !isNative : true
    },
    [isEvmChain],
  )

  const getPaymentAssets = useCallback(() => {
    if (asset) {
      return asset.collection.paymentAssets.filter(({ isNative, chain }) =>
        isOfferablePaymentAsset(isNative, chain.identifier),
      )
    } else if (collection) {
      return collection.paymentAssets.filter(({ isNative, chain }) =>
        isOfferablePaymentAsset(isNative, chain.identifier),
      )
    }
    return []
  }, [asset, collection, isOfferablePaymentAsset])

  const getCurrentPaymentAsset = useCallback(
    (paymentAssetRelayId: string) => {
      const currentPaymentAsset = getPaymentAssets().find(
        paymentAsset => paymentAssetRelayId === paymentAsset.relayId,
      )
      return currentPaymentAsset ?? getPaymentAssets()[0]
    },
    [getPaymentAssets],
  )

  const getMinBid = useCallback(
    (paymentAssetRelayId: string): BigNumber | undefined => {
      const paymentAsset = getCurrentPaymentAsset(paymentAssetRelayId)
      const paymentAssetUsdSpotPrice = paymentAsset.usdPrice
      const minimumListingPrice = tradeLimits.minimumBidUsdPrice
        ? offerPriceRounding(
            bn(tradeLimits.minimumBidUsdPrice).div(paymentAssetUsdSpotPrice),
            BigNumber.ROUND_UP,
          )
        : undefined

      const minBid = isEnglishAuction
        ? tradeData.bestAsk.perUnitPriceType.unit
        : undefined

      // TODO: When auction support is added to polygon, we'll need to compare minBid against minimumPayment
      return isEnglishAuction
        ? minBid
          ? bn(minBid)
          : undefined
        : minimumListingPrice
    },
    [
      getCurrentPaymentAsset,
      isEnglishAuction,
      tradeData,
      tradeLimits.minimumBidUsdPrice,
    ],
  )

  const getMinRaisePrice = useCallback(
    (paymentAssetId: string): BigNumber | undefined => {
      const bestBid = tradeData?.bestBid
      if (!bestBid) {
        return undefined
      }

      const paymentAsset = getCurrentPaymentAsset(paymentAssetId)

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!paymentAsset) {
        return undefined
      }

      const price = readPrice(bestBid)

      // Only apply the min raise price restriction if the current bestBid
      // payment asset is the same as the current payment asset
      if (
        !isEnglishAuction ||
        paymentAsset.relayId !== bestBid.payment.relayId
      ) {
        return undefined
      }

      return price.times(RAISE_PRICE_COEFFICIENT)
    },
    [isEnglishAuction, tradeData, getCurrentPaymentAsset],
  )

  const getBalance = useCallback(
    (paymentAssetId: string) => {
      return wallet.getBaseBalance(paymentAssetId)
    },
    [wallet],
  )

  const getTotalPrice = ({
    pricePerUnit,
    quantity,
  }: {
    pricePerUnit: string
    quantity: string
  }) => {
    return pricePerUnit && quantity
      ? bn(pricePerUnit).multipliedBy(quantity).toNumber()
      : null
  }

  const checkForHiddenListings = useCallback(async () => {
    const assetId = asset?.relayId
    let showHiddenListingWarning = false

    if (!toAddress) {
      return false
    }

    if (assetId) {
      showHiddenListingWarning = await checkWillReactivateListings(
        assetId,
        toAddress,
      )
    }

    return showHiddenListingWarning
  }, [asset?.relayId, toAddress])

  const isFungible =
    !!asset?.totalQuantity &&
    bn(asset.totalQuantity, asset.decimals).isGreaterThan(1)

  const maxQuantity = isCollectionOffer
    ? bn(MAX_COLLECTION_OFFER_QUANTITY)
    : asset?.totalQuantity
    ? bn(asset.totalQuantity, asset.decimals)
    : bn(0)

  const assetContracts = getCollectionAssetContracts(collection)
  const collectionAssetContract =
    isCollectionOffer && assetContracts.length === 1
      ? {
          contractAddress: assetContracts[0].address,
          chain: assetContracts[0].chain,
        }
      : null

  const eventParams: OfferModalEventParams = useMemo(
    () => ({
      offerType: isEnglishAuction
        ? "bid"
        : isCollectionOffer
        ? "collection offer"
        : "offer",
      collection: collection
        ? {
            slug: collection.slug,
            relayId: collection.relayId,
          }
        : undefined,
      asset: asset
        ? {
            relayId: asset.relayId,
            tokenAddress: asset.assetContract.address,
            tokenId: asset.tokenId,
          }
        : undefined,
    }),
    [asset, collection, isCollectionOffer, isEnglishAuction],
  )

  return {
    checkForHiddenListings,
    getBalance,
    getCurrentPaymentAsset,
    getPaymentAssets,
    getMinBid,
    getMinRaisePrice,
    getTotalPrice,
    isEnglishAuction,
    isFungible,
    maxQuantity,
    isCollectionOffer,
    collectionAssetContract,
    eventParams,
  }
}
