import { add } from "date-fns"
import { useTranslate } from "@/hooks/useTranslate"

export type ExpirationOption = {
  label: string
  value: string
  date: Date | undefined
}

export const useOfferExpirationOptions: () => ExpirationOption[] = () => {
  const t = useTranslate("orders")

  return [
    {
      label: t("offerModal.twelveHours", "12 hours"),
      value: "12h",
      date: add(new Date(), { hours: 12 }),
    },
    {
      label: t("offerModal.oneDay", "1 day"),
      value: "1d",
      date: add(new Date(), { days: 1 }),
    },
    {
      label: t("offerModal.threeDays", "3 days"),
      value: "3d",
      date: add(new Date(), { days: 3 }),
    },
    {
      label: t("offerModal.sevenDays", "7 days"),
      value: "7d",
      date: add(new Date(), { weeks: 1 }),
    },
    {
      label: t("offerModal.oneMonth", "1 month"),
      value: "1m",
      date: add(new Date(), { months: 1 }),
    },
    {
      label: t("offerModal.customDate", "Custom date"),
      value: "custom",
      date: undefined,
    },
  ]
}

export const useNewCollectionOfferExpirationOptions: () => ExpirationOption[] =
  () => {
    const t = useTranslate("orders")

    return [
      {
        label: t("offerModal.thirtyMin", "30 minutes"),
        value: "30min",
        date: add(new Date(), { minutes: 30 }),
      },
      {
        label: t("offerModal.oneHour", "1 hour"),
        value: "1h",
        date: add(new Date(), { hours: 1 }),
      },
      {
        label: t("offerModal.oneDay", "1 day"),
        value: "1d",
        date: add(new Date(), { days: 1 }),
      },
      {
        label: t("offerModal.sevenDays", "7 days"),
        value: "7d",
        date: add(new Date(), { days: 7 }),
      },
      {
        label: t("offerModal.oneMonth", "1 month"),
        value: "1m",
        date: add(new Date(), { months: 1 }),
      },
      {
        label: t("offerModal.custom", "Custom"),
        value: "custom",
        date: undefined,
      },
    ]
  }

export const useCollectionOfferExpirationOptions: () => ExpirationOption[] =
  () => {
    const t = useTranslate("orders")

    return [
      {
        label: t("offerModal.thirtyMin", "30 minutes"),
        value: "30min",
        date: add(new Date(), { minutes: 30 }),
      },
      {
        label: t("offerModal.oneHour", "1 hour"),
        value: "1h",
        date: add(new Date(), { hours: 1 }),
      },
      {
        label: t("offerModal.oneDay", "1 day"),
        value: "1d",
        date: add(new Date(), { days: 1 }),
      },
      {
        label: t("offerModal.threeDays", "3 days"),
        value: "3d",
        date: add(new Date(), { days: 3 }),
      },
      {
        label: t("offerModal.sevenDays", "7 days"),
        value: "7d",
        date: add(new Date(), { days: 7 }),
      },
      {
        label: t("offerModal.oneMonth", "1 month"),
        value: "1m",
        date: add(new Date(), { months: 1 }),
      },
      {
        label: t("offerModal.customDate", "Custom date"),
        value: "custom",
        date: undefined,
      },
    ]
  }

export const RAISE_PRICE_COEFFICIENT = 1.05
